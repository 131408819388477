import React from 'react';
import { Box, Typography, Button, Collapse, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const IntermediateResults = ({ interResults, createdAt, showResults, setShowResults }) => {

    // Function to calculate the time difference in seconds
    const getTimeDifference = (resultTimestamp) => {
        const createdTime = new Date(createdAt).getTime();
        const resultTime = new Date(resultTimestamp).getTime();
        return ((resultTime - createdTime) / 1000).toFixed(1); // Convert milliseconds to seconds
    };

    return (
        <Box sx={{ marginBottom: 2 }}>
            {/* Toggle Button */}
            <Button
                variant="contained"
                size="small"
                onClick={() => setShowResults(!showResults)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 1,
                    backgroundColor: '#1976d2',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#1565c0',
                    }
                }}
                startIcon={showResults ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
                {showResults ? "Скрыть ход запроса" : "Показать ход запроса"}
            </Button>

            {/* Collapsible Content */}
            <Collapse in={showResults}>
                <Paper
                    elevation={3}
                    sx={{
                        padding: 2,
                        // backgroundColor: '#f9f9f9',
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6" gutterBottom sx={{ color: '#1976d2' }}>
                        Ход запроса
                    </Typography>
                    {interResults.map((resultObj, index) => (
                        <Typography key={index} variant="body2" sx={{ marginBottom: 1 }}>
                            [+{getTimeDifference(resultObj.timestamp)} сек]: {resultObj.result}
                        </Typography>
                    ))}
                </Paper>
            </Collapse>
        </Box>
    );
};

export default IntermediateResults;
