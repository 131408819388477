import React from 'react';
import { Paper, Divider } from '@mui/material';
import DisplayedMessage from './DisplayedMessage'; // Adjust the path as necessary

const MessagesContainer = ({ messages, replaceSourceWithComponent, messagesEndRef }) => {
  return (
    <Paper sx={{ flexGrow: 1, overflowY: 'auto', padding: 2 }}>
      {messages.map((message, index) => (
        <React.Fragment key={index}>
          <DisplayedMessage
            message={message}
          />
          {index < messages.length - 1 && <Divider />}  {/* Divider managed here */}
        </React.Fragment>
      ))}
      <div ref={messagesEndRef} />
    </Paper>
  );
};

export default MessagesContainer;
