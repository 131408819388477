import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import RefSource from './RefSource';
import SourceModal from './SourceModal';
import axios from 'axios';

// const ReferenceSources = ({ references }) => {
const ReferenceSources = ({ sources = {}, references = {} ,selectedSource,setSelectedSource,getSourceDoc, handleSourceClick}) => {
  // const [sources, setSources] = useState([]);
  
  const { reference_dict = {} } = references || {};




  const handleCloseModal = () => {
    setSelectedSource(null); // Close the modal by clearing selectedSource
  };



  return (
    <Box
      sx={{
        maxHeight: '400px',
        overflowY: 'auto',
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {sources && (<Typography variant="h6">Источники:</Typography>)}
      {/* {sources.? && (<Typography variant="h5">Ссылки:</Typography>)} */}
      {sources && Object.keys(reference_dict).map((key) => (
        <RefSource key={key} refKey={key} request_id={reference_dict[key][0]}
          request_index={reference_dict[key][1]} source_doc={getSourceDoc(key)} onClick={handleSourceClick}
          />
      ))}
      {/* {sources && JSON.stringify(reference_dict, null, 2)} */}

      {/* Render the modal */}
      {selectedSource && (
        <SourceModal
          open={Boolean(selectedSource)}
          onClose={handleCloseModal}
          source_doc={selectedSource}
        />
      )}
    </Box>
  );


};

export default ReferenceSources;
