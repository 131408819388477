import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TextField,
    Box,
    Button,
    Typography,
    TableSortLabel,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

function DocumentList() {
    const [documentList, setDocumentList] = useState([]);
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/get_document_list`)
            .then((response) => response.json())
            .then((data) => {
                setDocumentList(data.document_list);
                setFilteredDocuments(data.document_list);
            });
    }, []);

    useEffect(() => {
        handleSearch(searchTerm);
    }, [documentList, searchTerm]);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedDocuments = [...filteredDocuments].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredDocuments(sortedDocuments);
    };

    const handleSearch = (term) => {
        setSearchTerm(term);
        if (term === '') {
            setFilteredDocuments(documentList);
        } else {
            const filtered = documentList.filter((doc) =>
                Object.values(doc).some((value) =>
                    value.toString().toLowerCase().includes(term.toLowerCase())
                )
            );
            setFilteredDocuments(filtered);
        }
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: 2, float: 'right'}} // Align to the right 
                onClick={() => navigate('/')}
            >
                Обратно в режим чата
            </Button>
            <Typography variant="h4" gutterBottom>
                Список документов
            </Typography>

            <TextField
                label="Поиск"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 2 }}
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell> {/* Number column header */}
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'company_name'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('company_name')}
                                >
                                    Название компании
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'title'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('title')}
                                >
                                    Заголовок
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'file_content_type'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('file_content_type')}
                                >
                                    Тип файла
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'period'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('period')}
                                >
                                    Период
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Скачать</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredDocuments.map((doc, index) => (
                            <TableRow key={doc.doc_id}>
                                <TableCell>{index + 1}</TableCell> {/* Document number */}
                                <TableCell>{doc.company_name}</TableCell>
                                <TableCell>{doc.title}</TableCell>
                                <TableCell>{doc.file_content_type}</TableCell>
                                <TableCell>{doc.period}</TableCell>
                                <TableCell>
                                    <IconButton
                                        component="a"
                                        href={`/doc_store/${doc.doc_id}`}
                                        download
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: 2, float: 'left' }} // Align to the right
                onClick={() => navigate('/')}
            >
                Обратно в режим чата
            </Button>
        </Box>
    );
}

export default DocumentList;
