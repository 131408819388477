// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AiChat, useAsBatchAdapter, useAsStreamAdapter, ResponseRenderer } from '@nlux/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Button, Typography, Container, Box, CircularProgress, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Text, ListItemButton } from '@mui/material';
import MuiMarkdown from 'mui-markdown';
// import { useLLMOutput, useStreamExample } from "@llm-ui/react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from 'remark-breaks';
import remarkGfm from "remark-gfm";
// import { LLMOutputComponent } from "@llm-ui/react";
// import { markdownLookBack } from "@llm-ui/markdown";
// import './App.css'
import { darkTheme, lightTheme } from './theme';
import '@nlux/themes/nova.css';
import { send } from './send.ts';
import ReferenceSources from './components/ReferenceSources.js';
import DocStatsWidget from './DocStatsWidget';
import ChatPage from './pages/ChatPage';
import DocumentList from './components/DocumentList'; // Import the new ChatPage
// import OldPage from './OldPage';

function App() {
  const [theme, setTheme] = useState('dark');
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
  };
  return (
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* <Route path="/" element={<OldPage />} /> */}
          <Route
            path="/"
            element={<ChatPage toggleTheme={toggleTheme} theme={theme} />}
          />
          <Route
            path="/documents"
            element={<DocumentList />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
export default App;

