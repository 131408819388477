// src/theme.js
import { createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const primaryGreen = '#30C46C'; // Converted from rgb(48, 196, 108)
const secondaryGreen = '#00875A'; // Example green for secondary, you can choose another


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: primaryGreen,
    },
    secondary: {
      main: secondaryGreen,
    },
  },
});

export { darkTheme, lightTheme };