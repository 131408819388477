// src/components/MessageSourceLink.js
import React from 'react';
import { Avatar, Tooltip, Link } from '@mui/material';

const MessageSourceLink = ({ id, sourceName,references,referenceDetails,onClick}) => {
  // console.log(`message source link id=${id},references`,references);
  // console.log(`message source link id=${id},referenceDetails`,referenceDetails);
  
  let request_id=references?.reference_dict[id][0] ?? null
  let index_in_request=references?.reference_dict[id][1] ?? null

  if (referenceDetails!=null){
    sourceName = referenceDetails?.[request_id]?.[index_in_request]?.node?.metadata?.fr_source_doc_name ?? null;
    // console.log(`doc_name=${JSON.stringify(doc_name)}`)
    // console.log(`referenceDetails=${JSON.stringify(referenceDetails)}`)
    
  }else{
    sourceName="Загрузка..."
  }
  // console.log(`message source link id=${id}, sourceName=${sourceName}, request_id=${request_id}, index_in_request=${index_in_request}`)
  // request_id=references?.request_dict.?id
  return (
    <Tooltip title={sourceName}>
      <Link
        href="#"
        underline="none"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          textDecoration: 'none',
        }}
        onClick={() => onClick(id)}
      >
        <Avatar
          sx={{
            width: 24,
            height: 24,
            bgcolor: 'primary.main',
            fontSize: 12,
            fontWeight: 'bold',
            marginRight: 0.5,
          }}
        >
          {id}
        </Avatar>
      </Link>
    </Tooltip>
  );
};



export default MessageSourceLink;
