// src/pages/ChatPage.js
import React, { useEffect, useRef, useState } from 'react';
import { AppBar, Toolbar, Tooltip, Typography, Box, Menu, Chip, Modal, IconButton, Select, Switch, MenuItem, Paper, Divider, TextField, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatMessage from '../models/ChatMessage';
import MessagesContainer from '../components/MessagesContainer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';

const ChatPage = ({ toggleTheme, theme }) => {
  const [model, setModel] = React.useState('gpt-3.5-turbo');
  const [messages, setMessages] = React.useState([]);
  const [inputtext, setInputText] = React.useState("")
  const [inProgress, setInProgress] = React.useState(false);
  const messagesEndRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalContent, setModalContent] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setOpenModal(true);
    handleMenuClose();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateLatestMessageWithRefs = (references, setMessages) => {
    setMessages((prevMessages) => {
      const currentMessage = { ...prevMessages[prevMessages.length - 1] };
      currentMessage.references = references;
      // console.log(currentMessage.references)
      return [
        ...prevMessages.slice(0, prevMessages.length - 1), // All messages except the last one
        currentMessage, // The updated last message
      ];
    });
  };

  const updateLatestMessageWithIntermediateResults = (inter_results_str, setMessages) => {
    setMessages((prevMessages) => {
      const currentMessage = { ...prevMessages[prevMessages.length - 1] };

      currentMessage.inter_results.push({
        result: inter_results_str,
        timestamp: new Date().toISOString() // Add the timestamp when the result is added
      });
      return [
        ...prevMessages.slice(0, prevMessages.length - 1), // All messages except the last one
        currentMessage, // The updated last message with new intermediate result
      ];
    });
  };


  const updateLatestMessageWithNewMessage = (newMessage, setMessages) => {
    setMessages((prevMessages) => {
      const currentMessage = { ...prevMessages[prevMessages.length - 1] };
      currentMessage.text = currentMessage.text + newMessage;
      return [
        ...prevMessages.slice(0, prevMessages.length - 1), // All messages except the last one
        currentMessage, // The updated last message
      ];
    });
  };


  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);  // This effect runs whenever `messages` changes

  useEffect(() => {
    const welcomeMessage = new ChatMessage(
      `# Краткая инструкция
  
  В настоящее время Помощник может отвечать на вопросы из двух баз данных:
  1. База данных новостей из 20 телеграм каналов с финансовой тематикой. 
  2. База данных официальных отчетных документов за 2023 год по 45 компаниям, которые входят в индекс Moex (подробный список по ссылке выше).
  
  ## Примеры вопросов:
  - Какая прибыль у Сбербанка в 2023 году?
  - Сравни прибыль Лукойла и Роснефти за 2023 год.
  - Какие новости за последние 2 недели по расчетам с китайскими банками?  (обратите внимание - можно указать временной интервал для поиска по новостям)
  - Прогнозы по ключевой ставке ЦБ РФ
  
  Помощник ориентирован на конкретные вопросы по определенной теме. Это означает, что вопросы, требующие суммаризации большого объема информации, например 'О какой компании чаще всего говорили в новостях за последний месяц?', будут вызывать затруднения.
  
  ## Обратная связь
  После получения ответа, пожалуйста, используйте кнопки обратной связи — эта информация используется для улучшения Помощника.`,
      "first_message"
    );
  
    setMessages([welcomeMessage]);
  }, []);
  
  const handleSendMessage = () => {
    if (inputtext === "") {
      return;
    }
    if (inProgress) {
      // show message to user that he can't submit new query until current one is finished
      alert("Пожалуйста подождите, пока текущий запрос не завершится");
      return;
    }
    setInputText("");
    setInProgress(true);
    setMessages((prevMessages) => [...prevMessages, new ChatMessage(inputtext, "user")])
    setMessages((prevMessages) => [...prevMessages, new ChatMessage("", "assistant")])
    const eventSource = new EventSource(`${process.env.REACT_APP_API_BASE_URL}/stream_query_i/?query_str=${inputtext}`);
    eventSource.onopen = () => {
      console.log('Connection to server opened.');
    };

    eventSource.onerror = (error) => {
      // observer.error(error);
      console.log('Error in SSE connection:');
      eventSource.close();
      setInProgress(false);
    };
    eventSource.addEventListener('references', async (e) => {
      console.log("references event received")
      const references = JSON.parse(e.data);
      updateLatestMessageWithRefs(references.references, setMessages);
      // setReferences(references.references);
    });
    eventSource.addEventListener('new_message', async (e) => {
      const data = JSON.parse(e.data);
      const decodedStr = decodeURIComponent(escape(window.atob(data.message)))
      updateLatestMessageWithNewMessage(decodedStr, setMessages);
      // message_to_fill.text=decodedStr

    });
    eventSource.addEventListener('intermediate_result', async (e) => {
      const data = JSON.parse(e.data);
      const decodedStr = decodeURIComponent(escape(window.atob(data.message)))
      updateLatestMessageWithIntermediateResults(decodedStr, setMessages);
    });
    eventSource.addEventListener('chat_request_info', async (e) => {
      const data = JSON.parse(e.data);
      setMessages((prevMessages) => {
        const currentMessage = { ...prevMessages[prevMessages.length - 1] };
        currentMessage.chat_request_id = data.chat_request_id;
        return [
          ...prevMessages.slice(0, prevMessages.length - 1), // All messages except the last one
          currentMessage, // The updated last message
        ];
      });

    });

    // eventSource.addEventListener("chat_request_info", (e) => {
    //   const data = JSON.parse(e.data);
    //   console.log(`onmessage1 ${data.chat_request_id}`);
    // });
  };



  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: 'space-between' }}>

          {/* Left-aligned Tooltip Menu Icon */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Tooltip title="Открыть меню"> */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            {/* </Tooltip> */}
          </Box>

          {/* Center-aligned Title */}
          <Typography variant="h6" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            Помощник трейдера
          </Typography>

          {/* Right-aligned Chip and Switch */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Chip label="Список документов" component={Link} to="/documents" clickable sx={{ marginRight: 2 }} />
            <Switch
              checked={theme === 'dark'}
              onChange={toggleTheme}
              inputProps={{ 'aria-label': 'Theme switcher' }}
            />
          </Box>

        </Toolbar>

        {/* Dropdown Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleOpenModal('Список известных проблем')}>
            Список известных проблем
          </MenuItem>
          <MenuItem onClick={() => handleOpenModal('Инструкции')}>
            Инструкции
          </MenuItem>
        </Menu>
      </AppBar>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {modalContent}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalContent === 'Список известных проблем' ? (
              <ul>
                <li>
                  В настоящее время в базе присутствуют отчетные документы только за 2023 год. 
                  Документов за первое полугодие 2024 нет (скоро будут загружены)
                </li>
                <li>Помощник не умеет отвечать на вопросы, которые требуют последовательного анализа большого объема данных. Т.е. вопрос 'О какой компании больше всего говорилось в новостях' вызовет сложности, если этой информации нет в какой-либо новости.</li>
                <li>Пока помощник не поддерживает контекст, т.е. он не поймет запрос "уточни такие-то моменты из своего предыдущего ответа"</li>
              </ul>
            ) : (
              'Здесь будут описаны нюансы использования поиска'
            )}
          </Typography>
        </Box>
      </Modal>


      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
        <Box sx={{ width: 200, padding: 2, borderRight: '1px solid #ccc' }}>

          {/* <Select
            value={model}
            onChange={handleModelChange}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="gpt-3.5-turbo">gpt-3.5-turbo</MenuItem>
            <MenuItem value="gpt-4.0">gpt-4.0</MenuItem>
          </Select> */}

        </Box>

        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <MessagesContainer messages={messages}
            messagesEndRef={messagesEndRef}
          />

          <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
            <TextField
              variant="outlined"
              value={inputtext}
              placeholder="Спросить Помощника"
              fullWidth
              sx={{ marginRight: 2 }}
              disabled={inProgress}
              onChange={(e) => setInputText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage(); // Trigger the same function as the Run button
                }
              }}
            />
            <Button variant="contained" onClick={handleSendMessage} disabled={inProgress} >
              Ввод
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatPage;
