import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link, Container} from '@mui/material';
import axios from 'axios';




const SourceModal = ({ open, onClose, source_doc }) => {
  let imageUrls = [];

  try {
    // Extract start and end pages
    const start_page = source_doc.fr_coords.start.page;
    const end_page = source_doc.fr_coords.end.page;
    const id_doc = source_doc["doc_id"];

    // Generate URLs for all pages from start_page to end_page
    for (let page = start_page; page <= end_page; page++) {
      const url = `${process.env.REACT_APP_API_BASE_URL}/get_doc_page_as_image?doc_id=${id_doc}&page_id=${page}`;
      imageUrls.push(url);
    }
  } catch (error) {
    console.error('Error generating image URLs:', error);
    imageUrls = [];  // Clear image URLs in case of an error
  }

  // Handle image navigation
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : imageUrls.length - 1));
  };
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex < imageUrls.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: 'auto',
          display: 'flex',
          gap: 2,
        }}
      >
        {/* Left side: Text content */}
        <Box sx={{ flex: 1, overflowY: 'auto', pr: 2 }}>
          <Typography variant="h5" gutterBottom>{source_doc.title}</Typography>
          {source_doc.doc_id && (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Link href={`/doc_store/${source_doc.doc_id}`}>
                Скачать полный PDF файл
              </Link>
            </Box>
          )}
          <Box sx={{ typography: 'body2', mb: 2 }}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{source_doc.text}</ReactMarkdown>
          </Box>
          <Typography variant="body2" color="textSecondary">
            Coordinates: {JSON.stringify(source_doc.fr_coords)}
          </Typography>
        </Box>

        {/* Right side: Image viewer */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {imageUrls.length > 0 && (
            <>
              <img
                src={imageUrls[currentImageIndex]}
                alt={`Page ${currentImageIndex + 1}`}
                // style={{ maxWidth: '100%', maxHeight: '100vh', marginBottom: '1px' }}
                style={{ maxWidth: '100%', marginBottom: '1px' }}
              />
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button variant="contained" onClick={handlePrevImage} disabled={imageUrls.length <= 1}>
                  Prev
                </Button>
                <Button variant="contained" onClick={handleNextImage} disabled={imageUrls.length <= 1}>
                  Next
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default SourceModal;










// const SourceModal = ({ open, onClose, source_doc }) => {
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);

//   // const imageUrls = source_doc.fr_coords.map(({ doc_id, page_id }) => 
//   //   `/get_image?doc_id=${doc_id}&page_id=${page_id}`
//   // );

//   let imageUrls =[];
//   //  ['http://localhost:8000/api/get_doc_page_as_image?page_id=1&doc_id=1', 'http://localhost:8000/api/get_doc_page_as_image?page_id=2&doc_id=1'];



//   const handlePrevImage = () => {
//     setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : imageUrls.length - 1));
//   };

//   const handleNextImage = () => {
//     setCurrentImageIndex((prevIndex) => (prevIndex < imageUrls.length - 1 ? prevIndex + 1 : 0));
//   };

//   return (
//     <Modal open={open} onClose={onClose}>
//       <Box
//         sx={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: '90%',
//           maxHeight: '90vh',
//           bgcolor: 'background.paper',
//           boxShadow: 24,
//           p: 4,
//           borderRadius: 2,
//           overflowY: 'auto',
//           display: 'flex',
//           gap: 2,
//         }}
//       >
//         {/* Left side: Text content */}
//         <Box sx={{ flex: 1, overflowY: 'auto', pr: 2 }}>
//           <Typography variant="h5" gutterBottom>{source_doc.title}</Typography>
//           <Box sx={{ typography: 'body2', mb: 2 }}>
//             <ReactMarkdown remarkPlugins={[remarkGfm]}>{source_doc.text}</ReactMarkdown>
//           </Box>
//           <Typography variant="body2" color="textSecondary">
//             {/* Coordinates: {JSON.stringify(source_doc.fr_coords)} */}
//           </Typography>
//         </Box>

//         {/* Right side: Image viewer */}
//         <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//           {imageUrls.length > 0 && (
//             <>
//               <img
//                 src={imageUrls[currentImageIndex]}
//                 //   alt={`Page ${source_doc.fr_coords[currentImageIndex].page_id}`}
//                 style={{ maxWidth: '100%', maxHeight: '60vh', marginBottom: '16px' }}
//               />
//               <Box sx={{ display: 'flex', gap: 2 }}>
//                 <Button variant="contained" onClick={handlePrevImage} disabled={imageUrls.length <= 1}>
//                   Prev
//                 </Button>
//                 <Button variant="contained" onClick={handleNextImage} disabled={imageUrls.length <= 1}>
//                   Next
//                 </Button>
//               </Box>
//             </>
//           )}
//         </Box>
//       </Box>
//     </Modal>
//   );
// };

// export default SourceModal;



// const SourceModal = ({ open, onClose, source_doc }) => {
//     const [images, setImages] = useState([]);
//     const [currentImageIndex, setCurrentImageIndex] = useState(0);



//     const fetchImages = async () => {
//         try {
//             const response = await axios.post('/get_doc_images', {
//                 images: source_doc.fr_coords.map(coord => ({
//                     source_doc_id: source_doc.id,
//                     page_id: coord.page_id,
//                 })),
//             });
//             setImages(response.data);  // Assuming the API returns the images list as described
//             setCurrentImageIndex(0);   // Reset to the first image
//         } catch (error) {
//             console.error('Error fetching images:', error);
//         }
//     };


//     useEffect(() => {
//         if (open) {
//             fetchImages();
//         }
//     }, [open, source_doc]);

//     const handlePrevImage = () => {
//         setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
//     };

//     const handleNextImage = () => {
//         setCurrentImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
//     };


//     return (
//         <Modal open={open} onClose={onClose}>
//             <Box
//                 sx={{
//                     position: 'absolute',
//                     top: '50%',
//                     left: '50%',
//                     transform: 'translate(-50%, -50%)',
//                     width: '90%',
//                     maxHeight: '90vh',
//                     bgcolor: 'background.paper',
//                     boxShadow: 24,
//                     p: 4,
//                     borderRadius: 2,
//                     overflowY: 'auto',
//                     display: 'flex',  // Split the modal into two parts
//                     gap: 2,
//                 }}
//             >
//                 {/* Left side: Text content */}
//                 <Box sx={{ flex: 1, overflowY: 'auto', pr: 2 }}>
//                     <Typography variant="h5" gutterBottom>{source_doc.title}</Typography>
//                     <Box sx={{ typography: 'body2', mb: 2 }}>
//                         <ReactMarkdown remarkPlugins={[remarkGfm]}>{source_doc.text}</ReactMarkdown>
//                     </Box>
//                     <Typography variant="body2" color="textSecondary">
//                         Coordinates: {JSON.stringify(source_doc.fr_coords)}
//                     </Typography>
//                 </Box>

//                 {/* Right side: Image viewer */}
//                 <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                     {images.length > 0 && (
//                         <>
//                             <img
//                                 src={`data:image/png;base64,${images[currentImageIndex].image_base64}`}
//                                 alt={`Page ${images[currentImageIndex].page_id}`}
//                                 style={{ maxWidth: '100%', maxHeight: '60vh', marginBottom: '16px' }}
//                             />
//                             <Box sx={{ display: 'flex', gap: 2 }}>
//                                 <Button variant="contained" onClick={handlePrevImage} disabled={images.length <= 1}>
//                                     Prev
//                                 </Button>
//                                 <Button variant="contained" onClick={handleNextImage} disabled={images.length <= 1}>
//                                     Next
//                                 </Button>
//                             </Box>
//                         </>
//                     )}
//                 </Box>
//             </Box>
//         </Modal>
//     );
// };

// export default SourceModal;
