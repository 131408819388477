class ChatMessage {
    /**
     * @param {string} text - The message text
     * @param {string} role - The role of the message
     */
    constructor(text, role) {
        this.text = text;
        this.role = role;
        this.references = null;
        this.chat_request_id = null;
        this.inter_results = []; // Initialize as an empty array for intermediate results
        this.created_at = new Date().toISOString(); // Log message creation time
    }

    /**
     * Adds an intermediate result to the inter_results array with a timestamp
     * @param {string} result - The intermediate result to add
     */
    addIntermediateResult(result) {
        this.inter_results.push({
            result,
            timestamp: new Date().toISOString(), // Log when this result is added
        });
    }
}

export default ChatMessage;
