import React, { useState } from 'react';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Box, Snackbar, Alert } from '@mui/material';

const FeedbackButtons = ({ chatRequestId }) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Function to handle Thumb Up click
  const handleThumbUpClick = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/store_feedback?chosen=good&chat_request_id=${chatRequestId}`);
      const result = await response.json();

      if (response.ok) {
        setSnackbarMessage(result.message || 'Спасибо!'); // Use message from server
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage(result.error || 'Ошибка отправки обратной связи на сервер');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage('Ошибка отправки обратной связи на сервер');
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
  };

  // Function to handle Thumb Down click
  const handleThumbDownClick = () => {
    setOpen(true);
  };

  // Function to handle the dialog close and send feedback
  const handleDialogClose = async (submit = false) => {
    if (submit) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/store_feedback?chosen=bad&comment=${encodeURIComponent(comment)}&chat_request_id=${chatRequestId}`);
        const result = await response.json();

        if (response.ok) {
          setSnackbarMessage(result.message || 'Спасибо, посмотрим в чем дело'); // Use message from server
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage(result.error || 'Ошибка отправки обратной связи на сервер');
          setSnackbarSeverity('error');
        }
      } catch (error) {
        setSnackbarMessage('Ошибка отправки обратной связи на сервер');
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    }
    setOpen(false);
    setComment('');
  };

  // Function to handle "Enter" keypress in the dialog
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleDialogClose(true);
    }
  };

  // Function to close the snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      {chatRequestId && (
        <>
          <Button
            variant="contained"
            color="success"
            startIcon={<ThumbUpAltIcon />}
            onClick={handleThumbUpClick}
            sx={{
              backgroundColor: '#4CAF50',
              '&:hover': {
                backgroundColor: '#45A049',
              },
            }}
          >
            Понравилось
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<ThumbDownAltIcon />}
            onClick={handleThumbDownClick}
            sx={{
              backgroundColor: '#F44336',
              '&:hover': {
                backgroundColor: '#E53935',
              },
            }}
          >
            Не понравилось
          </Button>
        </>
      )}

      {/* Dialog for thumbs down feedback */}
      <Dialog open={open} onClose={() => handleDialogClose(false)}>
        <DialogTitle>Почему вам не понравилось?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Ваш комментарий"
            type="text"
            fullWidth
            variant="outlined"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={handleKeyDown} // Added onKeyDown event listener
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Отмена
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary">
            Отправить
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for feedback confirmation */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000} // Automatically closes after 4 seconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FeedbackButtons;
